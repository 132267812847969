import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {OrderPlanning} from '../../../models/order-planning.interface';
import {LoadingPointStartEndHour} from '../../../models/planning.interface';
import {PlanningColumn, PlanningTooltipContentType} from '../../../models/wrappers/planning-timeline.interface';
import {Piste} from '../../../models/grade.interface';
import {UnavailabilitySummary} from '../../../models/unavailability-summary.interface';
import {GridUnavailability, Unavailability} from '../../../models/unavailability.interface';
import {LoadingStatusTruck} from "../../../models/loading-status-truck.enum";
import * as moment from 'moment-timezone';
import {getDateWithoutUtcTimezoneConversion} from "../../../utils/date.utils";

@Component({
    selector: 'app-optimized-planning-table',
    templateUrl: './optimized-planning-table.component.html',
    styleUrl: './optimized-planning-table.component.scss'
})
export class OptimizedPlanningTableComponent implements OnChanges {
    @Input() timelines: OrderPlanning[] = [];
    @Input() loadingPointStartEndHour: LoadingPointStartEndHour | undefined;
    @Input() planningColumns: PlanningColumn[] = [];
    @Input() unavaibilities: UnavailabilitySummary[] = [];
    @Input() activeDay: Date = new Date();
    timeSlots: string[] = [];
    pistes: Piste[] = [];
    schedulerStartTime: number = 0;
    schedulerEndTime: number = 0;
    tooltipTask: OrderPlanning | null = null;
    tooltipUnavailabilities: Unavailability[] | null = null;
    hoverOverIcon = false;
    displayedGrades = ['10/20', '15/25', '20/30', '35/50', '50/70', '70/100', '105/135', '160/220'];
    headerHeight: number = 110;
    slotHeight: number = 130;
    protected readonly PlanningTooltipContentType = PlanningTooltipContentType;
    protected readonly LoadingStatusTruck = LoadingStatusTruck;

    constructor() {
        this.generateTimeSlots();
    }

    getUnavailabilityDate(dateString: string): Date {
        return new Date(dateString);
    }

    getTooltipMessage(status: string): string {
        const messages: { [key: string]: string } = {
            loadingFinished: 'Chargement terminé',
            loadingOnProgress: 'Camion en cours de chargement',
            truckArrived: 'Camion arrivé à la raffinerie'
        };
        return messages[status] || 'Statut inconnu';
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['timelines'] || changes['planningColumns'] || changes['unavaibilities']) {
            this.initializePistes();
            this.timelines.forEach(timeline => {
                let piste = this.pistes.find(piste => piste.id === timeline.dockId);
                if (piste && piste?.tasks) {
                    piste.tasks.push(timeline);
                    this.pistes.forEach(piste => {
                        piste.grades?.sort((a, b) => {
                            const aValue = parseInt(a.split('/')[0]);
                            const bValue = parseInt(b.split('/')[0]);
                            return aValue - bValue;
                        });
                    });
                }
            });

        }
        if (changes['loadingPointStartEndHour'] && this.loadingPointStartEndHour) {
          this.schedulerStartTime = +this.loadingPointStartEndHour.openingHour.split(':')[0];
          this.schedulerEndTime = +this.loadingPointStartEndHour.closingHour.split(':')[0];
            this.generateTimeSlots();
        }
    }

    generateTimeSlots() {
        this.timeSlots = [];
        for (let hour = this.schedulerStartTime; hour < this.schedulerEndTime; hour++) {
            this.timeSlots.push(`${hour}h`);
            if (hour < this.schedulerEndTime) this.timeSlots.push(`${hour}h30`);
        }
        if (this.loadingPointStartEndHour?.openingHour)
            if (parseInt(this.loadingPointStartEndHour.openingHour.split(':')[1]) >= 30) {
                this.timeSlots.shift();
            }
        if (this.loadingPointStartEndHour?.closingHour)
            if (parseInt(this.loadingPointStartEndHour!.closingHour.split(':')[1]) <= 30 && parseInt(this.loadingPointStartEndHour!.closingHour.split(':')[1]) > 0) {
                this.timeSlots.push(`${this.schedulerEndTime}h`)
            }
    }

    initializePistes() {
        this.pistes = [];
        let todayUnavailabilities = this.getUnavailabilitiesForActiveDay();
        for (const element of this.planningColumns) {
            let unavailabilities = todayUnavailabilities
                .filter(unavailability => unavailability.name === element.name);
            this.pistes.push({
                id: element.id,
                name: element.name,
                tasks: [],
                grades: element.grades,
                unavailabilities: this.mergeIntersectingUnavailabilities(unavailabilities)
            });
        }
    }


    mergeIntersectingUnavailabilities(unavailabilities: Unavailability[]): GridUnavailability[] {
        if (unavailabilities.length === 0) return [];

        unavailabilities.sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

        const mergedUnavailabilities: GridUnavailability[] = [];
        let currentUnavailability: GridUnavailability = {
            startDate: this.setUnavailabilityStartDate(unavailabilities[0].startDate),
            endDate: this.setUnavailabilityEndDate(unavailabilities[0].endDate),
            unavailabilities: [unavailabilities[0]]
        };

        for (let i = 1; i < unavailabilities.length; i++) {
            const nextUnavailability = unavailabilities[i];
            const currentEndDate = new Date(currentUnavailability.endDate);
            const nextStartDate = new Date(nextUnavailability.startDate);

            if (nextStartDate <= currentEndDate) {
                currentUnavailability.endDate = new Date(Math.max(currentEndDate.getTime(), new Date(nextUnavailability.endDate).getTime())).toISOString();
                currentUnavailability.endDate = this.setUnavailabilityEndDate(currentUnavailability.endDate);
                currentUnavailability.unavailabilities.push(nextUnavailability);
            } else {
                mergedUnavailabilities.push(currentUnavailability);
                currentUnavailability = {
                    startDate: this.setUnavailabilityStartDate(nextUnavailability.startDate),
                    endDate: this.setUnavailabilityEndDate(nextUnavailability.endDate),
                    unavailabilities: [nextUnavailability]
                };
            }
        }

        mergedUnavailabilities.push(currentUnavailability);

        return mergedUnavailabilities;
    }

    getTaskPosition(startTime: Date): string {
        const pixelsPerMinute = this.slotHeight / 30; // slotHeight per 30 minutes
        const hour = new Date(startTime).getUTCHours();
        const minutes = new Date(startTime).getUTCMinutes();
        let localTime = this.timeSlots[0];
        let localHour = +localTime.split('h')[0];
        let localMinutes = localTime.includes('30') ? 30 : 0;
        // Calculate total minutes from the scheduler's start time
        const startMinutes = (hour - localHour) * 60 + (minutes || 0) - localMinutes;
        const taskTopPosition = startMinutes * pixelsPerMinute;
        return `${taskTopPosition + this.headerHeight}px`;
    }

    getTaskHeight(startTime: Date, endTime: Date): string {
        const pixelsPerMinute = this.slotHeight / 30; // slotHeight per 30 minutes
        const startHour = new Date(startTime).getUTCHours();
        const startMinutes = new Date(startTime).getUTCMinutes();
        const endHour = new Date(endTime).getUTCHours();
        const endMinutes = new Date(endTime).getUTCMinutes();

        const durationMinutes =
            (endHour - startHour) * 60 + (endMinutes || 0) - (startMinutes || 0);

        return `${durationMinutes * pixelsPerMinute - 2}px`;
    }

    getHeaderGradeClass(grade: string, piste: Piste): string {
        if (piste.grades?.includes(grade)) {
            return 'grade-' + grade.replace('/', '-').toLowerCase();
        }
        return '';
    }

    getGradeClass(grade: string): string {
        return 'grade-' + grade.replace('/', '-').toLowerCase();
    }

    transformUtcToLocalTime(utcTime: string): string {
        const utcDate = new Date(`1970-01-01T${utcTime}Z`);
        const localDate = new Date(utcDate.toLocaleString('en-US', {timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone}));
        return localDate.toTimeString().split(' ')[0];
    }

    setTooltipTask(task: OrderPlanning | null) {
        this.tooltipTask = task;
    }

    copyToClipboard(textToCopy: string) {
        navigator.clipboard.writeText(textToCopy);
    }

    setTooltipUnavailabilities(unavailability: Unavailability[] | null) {
        this.tooltipUnavailabilities = unavailability;
    }

    setUnavailabilityStartDate(startDate: string): string {
        let localTime = this.timeSlots[0];
        let day = this.getActiveDayWithOpeningOrClosingTime(localTime);
        let startDateObj = getDateWithoutUtcTimezoneConversion(startDate);
        if (startDateObj < day) {
            startDate = this.adjustDateToLocalTime(day);
        }
        return startDate;
    }

    setUnavailabilityEndDate(endDate: string): string {
        let localTime = moment(this.timeSlots[this.timeSlots.length - 1], 'H[h]mm').add(30, 'minutes').format('H[h]mm');
        let day = this.getActiveDayWithOpeningOrClosingTime(localTime);

        let endDateObj = getDateWithoutUtcTimezoneConversion(endDate);
        if (endDateObj > day) {
            endDate = this.adjustDateToLocalTime(day);
        }
        return endDate;
    }

    getUnavailabilitiesForActiveDay(): Unavailability[] {
        const activeDayString = new Date(this.activeDay.setHours(3, 0, 0, 0)).toISOString().split('T')[0];
        return this.unavaibilities
            .filter(unavailabilitySummary => unavailabilitySummary.day.split('T')[0] === activeDayString)
            .flatMap(unavailabilitySummary => unavailabilitySummary.unavailabilities);
    }

    adjustDateToLocalTime(day: Date): string {
        const offsetMilliseconds = day.getTimezoneOffset() * 60 * 1000;
        const adjustedDate = new Date(day.getTime() - offsetMilliseconds);
        return adjustedDate.toISOString();
    }

    getActiveDayWithOpeningOrClosingTime(localTime: string): Date {
        let day = this.activeDay;
        let localHour = +localTime.split('h')[0];
        let localMinutes = localTime.includes('30') ? 30 : 0;
        day.setHours(localHour, localMinutes, 0, 0);
        return day;
    }

    removeFocus(event: Event) {
        const target = event.target as HTMLElement;
        setTimeout(() => {
            target.blur();
        }, 1000);
    }


}
