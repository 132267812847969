import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from 'src/app/security/authentication.service';
import {siteCodeToName} from '../../../utils/sites.utils';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit{
  public connectedUser$ = this.authenticationService.connectedUser$;
  selectedSiteCode: string = '';
  selectedSiteName: string = '';
  sites: { code: string; name: string }[] = [];

  constructor(private readonly authenticationService: AuthenticationService) {
  }

  public logout() {
    this.authenticationService.logout();
  }

  ngOnInit() {
    this.authenticationService.roles$.subscribe(roles => {
      this.rolesToSites(roles);
      this.loadSelectedSite();
    });
  }

  loadSelectedSite() {
    const storedSiteCode = localStorage.getItem('selectedSite');
    if (storedSiteCode) {
      const site = this.sites.find(s => s.code === storedSiteCode);
      this.selectedSiteCode = site ? site.code : 'DGS';
      this.selectedSiteName = site ? site.name : 'Donges';
    } else {
      const sortedSites = [...this.sites].sort((a, b) => a.name.localeCompare(b.name));
      if (sortedSites.length > 0) {
        this.selectedSiteCode = sortedSites[0].code;
        this.selectedSiteName = sortedSites[0].name;
        localStorage.setItem('selectedSite', this.selectedSiteCode);
      }
    }
  }

  selectSite(code: string, name: string) {
    if (this.selectedSiteCode !== code) {
      this.selectedSiteCode = code;
      this.selectedSiteName = name;
      localStorage.setItem('selectedSite', code);
      location.reload();
    }
  }


  loadSitesFromRoles() {
    const roles = this.authenticationService.getcurrentUserRoles();
    this.rolesToSites(roles);
  }

  rolesToSites(roles: Array<string> | undefined) {
    if (!roles) {
      return;
    }
    const sites = roles
      .map(role => this.extractRoleCode(role))
      .filter((site, index, array) => array.indexOf(site) === index)
      .map(site => {
        return {
          code: site,
          name: siteCodeToName(site),
        };
      });

    this.sites = sites;
  }

  extractRoleCode(role: string): string {
    if (role.includes('_')) {
      return role.split('_')[0];
    }
    return role;
  }
}
