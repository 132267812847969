import {Component, Input} from '@angular/core';
import {OpenedDayAroundCurrentDay} from 'src/shared/models/opened-day-around-current-day';
import {
    PlanningColumnCell,
    PlanningTimeline,
    PlanningTooltipContent,
    PlanningTooltipContentType
} from 'src/shared/models/wrappers/planning-timeline.interface';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'planning-table',
    templateUrl: './planning-table.component.html',
    styleUrls: ['./planning-table.component.scss'],
})


export class PlanningTableComponent {
    @Input() timelines: PlanningTimeline[] = [];
    @Input() days: OpenedDayAroundCurrentDay = {
        previousDay: '',
        currentDay: '',
        nextDay: ''
    };
    tooltipContent: PlanningTooltipContent[] = [
        {displayName: '', cellPropertyName: 'mainText', type: PlanningTooltipContentType.DEFAULT},
        {displayName: '', cellPropertyName: 'thirdText', type: PlanningTooltipContentType.DEFAULT},
        {
            displayName: 'Créneau planifié : de ',
            cellPropertyName: 'initialStartDate',
            displaySecondName: ' à ',
            cellPropertySecondName: 'initialEndDate',
            type: PlanningTooltipContentType.DATE
        },
        {
            displayName: 'Créneau réoptimisé : de ',
            cellPropertyName: 'startDate',
            displaySecondName: ' à ',
            cellPropertySecondName: 'endDate',
            conditionDisplay: this.shouldDisplayReoptimizedDate,
            type: PlanningTooltipContentType.DATE
        },
        {displayName: 'BOL_ID : ', cellPropertyName: 'cellId', type: PlanningTooltipContentType.TRUNCATE},
        {displayName: 'Chargé : ', cellPropertyName: 'realLoadingDate', type: PlanningTooltipContentType.DATE},
    ];

    copyToClipboard(textToCopy: string) {
        navigator.clipboard.writeText(textToCopy);
    }


    shouldDisplayReoptimizedDate(cell: PlanningColumnCell): boolean {
        return new Date(cell.initialStartDate ?? 0).getTime() !== new Date(cell.startDate).getTime();
    }
}