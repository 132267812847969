<div class="calendar-card-unavaibilities">
    <div class="calendar-card-unavaibilities-header">
        <title-typography [level]="5" class="ms-3">
            {{ (date | dateComparator) === 0 ? "Aujourd'hui" : (date | date: 'EEE d MMM':timeZone) }}
        </title-typography>
    </div>
    <!-- Docks -->
    <div class="calendar-card-docks-body">
        <ng-template [ngTemplateOutlet]="macroSlotsTemplate"></ng-template>

        <div class="dock-card" *ngFor="let dockUnavaibility of unavailabilityCardInput.docks">
            <ng-container *ngFor="let microSlot of dockUnavaibility.macroSlotsUnavailability">
                <div
                        *ngTemplateOutlet="
    microSlotElement;
    context: {
      context: ITEM_CLASS_TYPE.DOCK,
      title: dockUnavaibility.item.name,
      itemId: dockUnavaibility.item?.id,
      overlay: overlayDock,
      tooltipName: dockUnavailabilityTooltip,
      unavailabilities: microSlot.unavailabilities,
      classes: 'dock-card-macro-slot',
      direction: 'bottom',
      microSlot: microSlot,
      dockUnavaibility: dockUnavaibility
    }
  "
                ></div>

                <ng-template #dockUnavailabilityTooltip>
                    <ng-container *ngTemplateOutlet="tooltipContent; context: { microSlot: microSlot }"></ng-container>
                </ng-template>
            </ng-container>
            <div class="dock-display">
                <text-typography> {{ dockUnavaibility.item.name }}</text-typography>
            </div>
        </div>
    </div>
    <!-- grades -->
    <div class="calendar-card-docks-footer">
        <ng-template [ngTemplateOutlet]="macroSlotsTemplate"></ng-template>

        <div *ngFor="let gradeUnavaibility of unavailabilityCardInput.grades">
            <div class="d-flex flex-column align-items-center mt-3">
                <text-typography class="mb-1">{{ gradeUnavaibility.item?.grade }}</text-typography>
                <div class="product-pill-hour-row">
                    <div class="product-pill-hour-wrapper"
                         *ngFor="let microSlot of gradeUnavaibility.macroSlotsUnavailability">
                        <ng-container
                                *ngTemplateOutlet="
                microSlotElement;
                context: {
                  context: ITEM_CLASS_TYPE.GRADE,
                  title: gradeUnavaibility.item?.grade,
                  itemId: gradeUnavaibility.item?.id,
                  overlay: overlayGrade,
                  tooltipName: gradeUnavailabilityTooltip,
                  unavailabilities: microSlot.unavailabilities,
                  classes: 'product-hour-pill',
                  direction: 'top',
                  microSlot: microSlot,
                  gradeUnavaibility: gradeUnavaibility
                }
              "
                        ></ng-container>
                        <ng-template #gradeUnavailabilityTooltip>
                            <ng-container
                                    *ngTemplateOutlet="tooltipContent; context: { microSlot: microSlot }"></ng-container>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- templates -->
<ng-template #macroSlotsTemplate>
    <div class="dock-macro-slot" *ngIf="macroSlots">
        <div class="macro-slot" *ngFor="let macroSlot  of macroSlots ">
            <text-typography>
                {{ (macroSlot | slotTZConverter).startHour }}
            </text-typography>
        </div>
        <div class="macro-slot">
            <text-typography>
                {{ (macroSlots.at(macroSlots.length - 1)| slotTZConverter).endHour }}
            </text-typography>
        </div>
    </div>
</ng-template>

<ng-template #tooltipContent let-microSlot="microSlot">
    <div *ngFor="let unavailability of microSlot.unavailabilities">
        <text-typography>{{ unavailability.startDate | dateFormat: unavailability.endDate }}
            : {{ unavailability.reason?.reason }}
        </text-typography>
    </div>
</ng-template>

<ng-template
    #microSlotElement
    let-context="context"
    let-title="title"
    let-itemId="itemId"
    let-overlay="overlay"
    let-tooltipName="tooltipName"
    let-unavailabilities="unavailabilities"
    let-classes="classes"
    let-direction="direction"
    let-microSlot="microSlot"
    let-dockUnavaibility="dockUnavaibility"
    let-gradeUnavaibility="gradeUnavaibility"
>
    <div
            (click)="setCurrentSelectedDockGradeMicroSlotUnavailability(microSlot, dockUnavaibility, gradeUnavaibility);openOverlayWithData({context: context, title: title, itemId: itemId, itemData: unavailabilities}, overlay, $event)"
            [class]="classes"
            [tooltipStyleClass]="unavailabilities && unavailabilities.length !== 0 && unavailabilities[0].toEdit ? 'tooltip-info-unavailability-edit' : 'tooltip-info-unavailability'"
            [pTooltip]="tooltipName"
            [tooltipDisabled]="!(unavailabilities && unavailabilities.length !== 0)"
            [ngClass]="unavailabilities && unavailabilities.length !== 0 ? (unavailabilities[0].toEdit ? 'to-edit' : 'active') : ''"
            [tooltipPosition]="direction"
            onkeypress=""
    ></div>
</ng-template>
<ng-template
    #popupForm
    let-overlay="overlay"
    let-title="title"
    let-item="item"
    let-itemType="itemType"
    let-reasons="reasons"
    let-operation="operation"
>
    <div class="d-flex">
        <text-typography customCssClass="title-text-light"
        >{{ (operation === ITEM_OPERATION_TYPE.CREATE ? 'Nouvelle ' : 'Modification de la ') + 'contrainte' }}&nbsp;
        </text-typography
        >
        <text-typography customCssClass="title-text-bold">{{
                unavailabilityForm.controls['selectedReason'].value?.reason === 'Alerte orage' ||
                unavailabilityForm.controls['selectedReason'].value?.reason === 'Grève interne' ? 'Tous les quais et tous les produits' : title
            }}
        </text-typography>
    </div>
    <br/>
    <form [formGroup]="unavailabilityForm" (ngSubmit)="editUnavailability(itemType, operation, overlay)">
        <div class="container">
            <div class="row">
                <div class="col">
                    <span for="reason" class="unavaibilities-label">Description type intervention <span
                            class="unavaibilities-wildcard">*</span></span>
                    <p-dropdown
                            formControlName="selectedReason"
                            [options]="dropdownOptions"
                            [class.ng-invalid]="unavailabilityForm.controls['selectedReason'].invalid && unavailabilityForm.controls['selectedReason'].touched"
                            [class.ng-dirty]="unavailabilityForm.controls['selectedReason'].invalid && unavailabilityForm.controls['selectedReason'].touched"
                            optionLabel="reason"
                            dataKey="id"
                            placeholder="Select a description"
                            class="unavaibilities-description"
                            [required]="true"
                            (onChange)="setFixedDatesIfNeeded($event)"
                    ></p-dropdown>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-3">
                    <small
                            *ngIf="unavailabilityForm.controls['selectedReason'].invalid && unavailabilityForm.controls['selectedReason'].touched"
                            class="p-error"
                    >La description est requise</small
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <input type="hidden" formControlName="id"/>
                    <input type="hidden" formControlName="fromDatabase"/>
                    <input type="hidden" formControlName="toEdit"/>
                    <input type="hidden" formControlName="title"/>
                    <input type="hidden" formControlName="dockGradeId"/>
                    <input type="hidden" formControlName="type"/>
                    <label for="startDate" class="unavaibilities-label">Date</label>
                    <p-calendar
                            (onBlur)="validateStartDateTime()"
                            inputId="startDate"
                            formControlName="startDate"
                            [minDate]="minStartDate"
                            [maxDate]="maxStartDate!"
                            dateFormat="dd/mm/yy"
                            dataType="string"
                            (onSelect)="onStartDateSelect($event)"
                            [class.ng-invalid]="unavailabilityForm.controls['startDate'].invalid && unavailabilityForm.controls['startDate'].touched"
                            [class.ng-dirty]="unavailabilityForm.controls['startDate'].invalid && unavailabilityForm.controls['startDate'].touched"
                            [iconDisplay]="'input'"
                            [showIcon]="true"
                            [ngClass]="{ 'p-disabled': unavailabilityForm.get('startDate')?.disabled }">
                        >
                    </p-calendar>
                </div>
                <div class="col-2">
                    <label for="startHour" class="unavaibilities-label">Heure</label>
                    <p-calendar
                            (onBlur)="validateStartDateTime()"
                            inputId="startHour"
                            [timeOnly]="true"
                            dataType="string"
                            [class.ng-invalid]="unavailabilityForm.controls['startHour'].invalid && unavailabilityForm.controls['startHour'].touched"
                            [class.ng-dirty]="unavailabilityForm.controls['startHour'].invalid && unavailabilityForm.controls['startHour'].touched"
                            formControlName="startHour"
                    ></p-calendar>
                </div>
                <div class="col-2 unavaibilities-arrow">
                    <icon type="arrow_right_alt" customIconClass="material-icons arrow"></icon>
                </div>
                <div class="col-3">
                    <label for="endDate" class="unavaibilities-label">Date&nbsp;<span
                            class="unavaibilities-label-legend">(si connue)</span></label>
                    <p-calendar
                            (onBlur)="validateEndDateTime()"
                            inputId="endDate"
                            formControlName="endDate"
                            [minDate]="minEndDate!"
                            dateFormat="dd/mm/yy"
                            dataType="string"
                            (onSelect)="onEndDateSelect($event)"
                            [class.ng-invalid]="unavailabilityForm.controls['endDate'].invalid && unavailabilityForm.controls['endDate'].touched"
                            [class.ng-dirty]="unavailabilityForm.controls['endDate'].invalid && unavailabilityForm.controls['endDate'].touched"
                            [iconDisplay]="'input'"
                            [showIcon]="true"
                            [ngClass]="{ 'p-disabled': unavailabilityForm.get('endDate')?.disabled }">
                        >
                    </p-calendar>
                </div>
                <div class="col-2">
                    <label for="endHour" class="unavaibilities-label">Heure</label>
                    <p-calendar
                            (onBlur)="validateEndDateTime()"
                            inputId="endHour"
                            [timeOnly]="true"
                            dataType="string"
                            [class.ng-invalid]="unavailabilityForm.controls['endHour'].invalid && unavailabilityForm.controls['endHour'].touched"
                            [class.ng-dirty]="unavailabilityForm.controls['endHour'].invalid && unavailabilityForm.controls['endHour'].touched"
                            formControlName="endHour"
                    ></p-calendar>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-3">
                    <small *ngIf="unavailabilityForm.controls['startDate'].invalid && unavailabilityForm.controls['startDate'].touched"
                           class="p-error"
                    >La date est requise</small
                    >
                </div>
                <div class="col-2">
                    <small
                            *ngIf="
              unavailabilityForm.controls['startHour'].errors?.['invalidStartDate'] &&
              unavailabilityForm.controls['startHour'].invalid &&
              unavailabilityForm.controls['startHour'].touched
            "
                            class="p-error"
                    >La date de début ne peut pas être inférieure à la date actuelle </small
                    >
                    <small
                            *ngIf="
              !unavailabilityForm.controls['startHour'].errors?.['invalidTimeRange'] &&
              !unavailabilityForm.controls['startHour'].errors?.['invalidStartDate'] &&
              unavailabilityForm.controls['startHour'].invalid &&
              unavailabilityForm.controls['startHour'].touched
            "
                            class="p-error"
                    >L'heure de début est requise</small
                    >
                </div>
                <div class="col-2">
                </div>
                <div class="col-3">
                    <small *ngIf="unavailabilityForm.controls['endDate'].invalid && unavailabilityForm.controls['endDate'].touched"
                           class="p-error"
                    >La date est requise</small
                    >
                </div>
                <div class="col-2">
                    <small *ngIf="unavailabilityForm.controls['startHour'].errors?.['invalidTimeRange']"
                           class="p-error">
                        L'heure de fin doit être supérieur à l'heure de début
                    </small>
                    <small
                            *ngIf="
              !unavailabilityForm.controls['endHour'].errors?.['invalidTimeRange'] &&
              unavailabilityForm.controls['endHour'].invalid &&
              unavailabilityForm.controls['endHour'].touched
            "
                            class="p-error"
                    >L'heure de fin est requise</small
                    >
                </div>
            </div>
            <div class="row">
                <label for="comment">Commentaires additionels </label>
                <textarea formControlName="comment" class="form-control" id="comment" rows="3"></textarea>
            </div>
            <div class="row mb-4">
                <div class="col-9">
                    <small *ngIf="unavailabilityForm.controls['comment'].hasError('maxlength')" class="p-error">
                        Le commentaire ne peut pas dépasser 100 caractères
                    </small>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col" *ngIf="operation === ITEM_OPERATION_TYPE.UPDATE">
                    <button-element
                            customCssClass="btn btn-secondary"
                            (click)="editUnavailability(itemType, ITEM_OPERATION_TYPE.DELETE, overlay); overlay.onCloseClick($event)"
                            onkeypress=""
                            [isDisabled]="isDeleteDisabled()"
                    >
                        <span class="material-icons" aria-hidden="true">delete</span>
                        Supprimer
                    </button-element>
                </div>
                <div class="col">
                    <button-element customCssClass="btn btn-tertiary" (click)="overlay.onCloseClick($event)"
                                    onkeypress="">
                        <span class="material-icons" aria-hidden="true">close</span> Annuler
                    </button-element>
                </div>
                <div class="col">
                    <button-element [type]="'submit'" [isDisabled]="isSaveDisabled()">
                        <span class="material-icons" aria-hidden="true">check</span>
                        Appliquer
                    </button-element>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<p-overlayPanel #overlayGrade [showCloseIcon]="true" styleClass="unavailabilities-panel">
    <ng-container
            *ngTemplateOutlet="
                  popupForm;
                  context: {
                    overlay: overlayGrade,
                    title: selectedGradeUnavailability?.item?.name,
                    item: selectedMicroSlot?.unavailabilities,
                    itemType: ITEM_TYPE_SET.grade,
                    reasons: gradeUnavailabilityReasons,
                    operation: selectedMicroSlot?.unavailabilities && selectedMicroSlot?.unavailabilities?.length ? ITEM_OPERATION_TYPE.UPDATE : ITEM_OPERATION_TYPE.CREATE
                  }
                "
    />
</p-overlayPanel>
<p-overlayPanel #overlayDock [showCloseIcon]="true" styleClass="unavailabilities-panel">
    <ng-container
            *ngTemplateOutlet="
              popupForm;
              context: {
                overlay: overlayDock,
                title: selectedDockUnavailability?.item?.name,
                item: selectedMicroSlot?.unavailabilities,
                itemType: ITEM_TYPE_SET.dock,
                reasons: dockUnavailabilityReasons,
                operation: selectedMicroSlot?.unavailabilities && selectedMicroSlot?.unavailabilities?.length ? ITEM_OPERATION_TYPE.UPDATE : ITEM_OPERATION_TYPE.CREATE
                }
            "
    />
</p-overlayPanel>
<p-overlayPanel #overlayAlerteOrage [showCloseIcon]="true" styleClass="unavailabilities-panel">
    <div class="alert-content">
        <text-typography customCssClass="title-text-light">Contrainte générale</text-typography>
        <br/>
        <div class="container pt-3">
            <div class="row">
                <div class="col">
                    <span class="unavaibilities-label">Description type intervention</span>
                    <div *ngIf="dropdownOptions && dropdownOptions.length > 0">
                        <p-dropdown
                                [placeholder]="dropdownOptions[0].reason"
                                class="unavaibilities-description"
                                [disabled]="true"
                        ></p-dropdown>
                    </div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center justify-content-center">
        <button-element
                customCssClass="btn btn-primary"
                [isDisabled]="canNotUpdate()"
                (click)="!canNotUpdate() && endAlerteOrage(overlayAlerteOrage, ITEM_OPERATION_TYPE.DELETE)"
                *ngIf="startDateIsAfterNow(unavailabilityForm.controls['startDate'].value)"
                onKeyPress=""
        >
            <span class="material-icons" aria-hidden="true">delete</span>Supprimer l'alerte orage
        </button-element>
        <button-element
                customCssClass="btn btn-primary"
                [isDisabled]="canNotUpdate()"
                (click)="!canNotUpdate() && endAlerteOrage(overlayAlerteOrage, ITEM_OPERATION_TYPE.UPDATE)"
                *ngIf="!startDateIsAfterNow(unavailabilityForm.controls['startDate'].value)"
                onKeyPress=""
        >
            <span class="material-icons" aria-hidden="true">close</span>Fin de l'alerte orage
        </button-element>
    </div>
</p-overlayPanel>
