/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { OverlayPanel } from 'primeng/overlaypanel';
import { subscribeOn } from 'rxjs';
import { TimeZoneConverter } from '../../../infrastructure/resources/time-zone-converter';
import { Slot } from '../../../models/macro-slot.interface';
import { Unavailability } from '../../../models/unavailability.interface';
import {
  ITEM_CLASS_TYPE,
  ITEM_OPERATION_TYPE,
  ITEM_TYPE_SET,
  ItemOutputResponse,
  ItemType,
  UnavailabilityCardInput
} from '../../../models/wrappers/unavailabilities-card-input.interface';
import { dateTimeZoneToUTC, isToday } from '../../../utils/date.utils';
import { UnavailabilityReason } from 'src/shared/models/unavailability-reason.interface';

interface OverlayParams {
  context: ITEM_CLASS_TYPE,
  title: string,
  itemId: string,
  itemData: any
}
// /!\ this component should be refactored
@Component({
  selector: 'app-calendar-card-unavaibilities',
  templateUrl: './calendar-card-unavaibilities.component.html',
  styleUrls: ['./calendar-card-unavaibilities.component.scss'],
})
export class CalendarCardUnavaibilitiesComponent implements OnInit {

  @Input() unavailabilityCardInput: UnavailabilityCardInput = {
    docks: [],
    grades: [],
    day: new Date()
  };
  @Input() macroSlots: Slot[] | null = [];
  @Input() date: Date | null = null;
  @Input() dockUnavailabilityReasons: UnavailabilityReason[] = [];
  @Input() gradeUnavailabilityReasons: UnavailabilityReason[] = [];
  @Output() editUnavaibility: EventEmitter<ItemOutputResponse> = new EventEmitter<ItemOutputResponse>();

  readonly ITEM_TYPE_SET: ITEM_TYPE_SET = ITEM_TYPE_SET;
  readonly ITEM_OPERATION_TYPE: typeof ITEM_OPERATION_TYPE = ITEM_OPERATION_TYPE;
  readonly ITEM_CLASS_TYPE: typeof ITEM_CLASS_TYPE = ITEM_CLASS_TYPE;
  fixedEndHour: string = '20:00';

  @ViewChild('overlayAlerteOrage') overlayAlerteOrage!: OverlayPanel;

  ngOnInit() {
    this.initializeForm();
  }

  constructor(
      private formBuilder: FormBuilder,private timeZoneConverter : TimeZoneConverter
  ) {
    this.minDate = new Date();
    this.minDate.setUTCHours(0, 0, 0, 0);
    this.minEndDate = new Date(this.minDate);
  }

  timeZone : string = this.timeZoneConverter.timeZone;

  dropdownOptions!: any;
  minDate: Date;
  minEndDate: Date;
  maxEndDate!: Date;
  unavailabilityForm!: FormGroup;
  startDateSelected: Date = new Date();

  initializeForm() {
    this.unavailabilityForm = this.formBuilder.group({
      id: [''],
      groupId: [''],
      title: [''],
      fromDatabase: [''],
      toEdit: [''],
      startDate: ['', Validators.required],
      startHour: ['', Validators.required],
      endDate: [''],
      endHour: [''],
      comment: ['',Validators.maxLength(100)],
      selectedReason: [null, Validators.required],
      dockGradeId: [null],
      type: [null],
    }, { validators: this.timeRangeValidator });
  }

  timeRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!(control instanceof FormGroup)) {
      return null;
    }

    const startHourControl = control.get('startHour');
    const endHourControl = control.get('endHour');
    const startDateControl = control.get('startDate');
    const endDateControl = control.get('endDate');

    this.validateRangeTime(startHourControl, endHourControl, startDateControl, endDateControl);
    return control.validator;
  };

  validateEndDateTime(): void {
    const endDateControl = this.unavailabilityForm.get('endDate');
    const startHourControl = this.unavailabilityForm.get('startHour');
    const endHourControl = this.unavailabilityForm.get('endHour');
    const startDateControl = this.unavailabilityForm.get('startDate');

    if (endDateControl && endHourControl) {
      const isEndDateEmpty = !endDateControl.value;
      const isEndHourEmpty = !endHourControl.value;
      const resetEndDateErrors = () => {
        endDateControl.setErrors(null);
        endHourControl.setErrors(null);
      };

      if (isEndDateEmpty && isEndHourEmpty) {
        resetEndDateErrors();
      } else if (isEndDateEmpty && !isEndHourEmpty) {
        endDateControl.setErrors({ customError: true });
      } else if (!isEndDateEmpty && isEndHourEmpty) {
        endHourControl.setErrors({ customError: true });
      } else {
        resetEndDateErrors();
      }
    }
    this.validateRangeTime(startHourControl, endHourControl, startDateControl, endDateControl);
  }

  validateRangeTime(startHourControl: any, endHourControl: any, startDateControl: any, endDateControl: any): void {
    const isValidDates = startDateControl && endDateControl && startDateControl.value && endDateControl.value;
    const isValidHours = startHourControl && endHourControl && startHourControl.value && endHourControl.value;

    if (isValidDates && isValidHours) {

      const adjustedStartDate = this.mergeDateAndTime(startDateControl.value, startHourControl.value); moment(startDateControl.value + startHourControl.startHour).format();
      const adjustedEndDate = this.mergeDateAndTime(endDateControl.value, endHourControl.value);

      const isDateRangeValid = new Date(adjustedStartDate).getTime() < new Date(adjustedEndDate).getTime();

      if (!isDateRangeValid) {
        startHourControl.setErrors( { 'invalidTimeRange': true });
        endHourControl.setErrors( { 'invalidTimeRange': true });
      } else {
        startHourControl.setErrors( null);
        endHourControl.setErrors( null);
      }
    }
  }

  resetForm() {
    this.unavailabilityForm.reset();
    this.unavailabilityForm.get('endDate')?.enable();
    this.unavailabilityForm.get('endHour')?.enable();
  }

  onStartDateSelect(date: Date) {
    this.startDateSelected = date;
    this.minEndDate = new Date(date);
    this.setFixedDatesIfNeeded({value: this.unavailabilityForm.getRawValue().selectedReason});
  }

  onEndDateSelect(date: Date) {
    this.maxEndDate = new Date(date);
  }

  openOverlayWithData(overlayParams: OverlayParams, overlayPanel: any, event: Event) {
    this.resetForm();
    if (overlayParams.context === ITEM_CLASS_TYPE.DOCK) {
      this.dropdownOptions = this.dockUnavailabilityReasons;
    } else if (overlayParams.context === ITEM_CLASS_TYPE.GRADE) {
      this.dropdownOptions = this.gradeUnavailabilityReasons;
    }
    if (overlayParams.itemData[0] && overlayParams.itemData[0].id !== '') {
        this.populateForm(overlayParams.itemData[0], overlayParams.title, overlayParams.itemId);
    } else {
      this.populateFormDefault(overlayParams.title, overlayParams.itemId);
    }

    if (overlayParams.itemData[0] &&
      overlayParams.itemData[0].reason !== undefined &&
      overlayParams.itemData[0].reason.reason === 'Alerte orage' &&
       overlayParams.itemData[0].reason.onlyAllowClosureAndFixedEndDate &&
       overlayParams.itemData[0].fromDatabase
    ) {
      this.dropdownOptions = this.dropdownOptions.filter((reason: any) => reason.reason === 'Alerte orage');
      const currentDate = new Date();
      const endDate = new Date(overlayParams.itemData[0].endDate ? overlayParams.itemData[0].endDate.split('Z')[0] : null);
      if (endDate > currentDate) {
        overlayPanel = this.overlayAlerteOrage;
      }else {
        return;
      }
    }
    overlayPanel.show(event);
  }

  endAlerteOrage(overlay: OverlayPanel, itemOperationType: ITEM_OPERATION_TYPE): void {
    this.setEndHourToNow();

    this.editUnavailability(ITEM_TYPE_SET.dock, itemOperationType, overlay);
  }
  setEndHourToNow(): void {
    const formattedTime = new Date().toTimeString().slice(0, 5); // Format as HH:MM
    this.unavailabilityForm.patchValue({
      endHour: formattedTime,
    });
  }
  populateForm(data: any, title: string, itemId: string) {

    function formatTime(date: Date): string {
      const hours = String(date.getUTCHours()).padStart(2, '0');
      const minutes = String(date.getUTCMinutes()).padStart(2, '0');
      return `${hours}:${minutes}`;
    }

    const startDate = moment(data.startDate.replace('Z', '')).toDate();

    this.unavailabilityForm.patchValue({
      id: data.id,
      groupId: data.groupId,
      title: title,
      fromDatabase: data.fromDatabase,
      toEdit: data.toEdit,
      startDate: startDate,
      startHour: moment(startDate).format('HH:mm'),
      comment :data.comment,
      selectedReason: data.reason,
      dockGradeId: itemId,
      type: data.type
    });

    if (data.endDate) {
      const endDate = moment.parseZone(data.endDate).toDate();
      this.unavailabilityForm.patchValue({
        endDate: new Date(endDate),
        endHour: formatTime(endDate),
      });
    }
    this.setFixedDatesIfNeeded({value: data.reason});
  }

  populateFormDefault(title: string, itemId: string) {
    this.unavailabilityForm.patchValue({
      id: null,
      groupId: null,
      title: title,
      fromDatabase: false,
      toEdit: false,
      startDate: null,
      startHour: null,
      selectedReason: null,
      dockGradeId: itemId,
      type: null
    });
  }

  editUnavailability(itemType: ItemType, operationType: ITEM_OPERATION_TYPE, overlay: OverlayPanel): void {
    const formValues = this.unavailabilityForm.getRawValue();
      if (this.unavailabilityForm.valid || formValues.selectedReason.reason === 'Alerte orage') {
        this.emitItemOutputResponse(this.createItemFromControl(formValues, itemType, operationType));
        this.hideOverlayAndEmitRefresh(overlay);
      } else {
        this.markAllAsTouched(this.unavailabilityForm);
      }
  }


  convertToISOString(date: Date, hours: number, minutes: number): string {
    const mergedDate: Date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0, 0));
    return mergedDate.toISOString().replace(/.\d+.\d+Z$/g, 'Z');
  }


  mergeDateAndTime(dateStr: string, hourStr: string) {
    if ((dateStr && dateStr !== '') && (hourStr && hourStr !== '')) {
      const date: Date = this.extractDate(dateStr);
      const hours: Date = this.extractHours(hourStr);
      return this.convertToISOString(date, hours.getUTCHours(), hours.getUTCMinutes());
    }
    return '';
  }

  createItemFromControl(control: any, itemType: ItemType, operationType: ITEM_OPERATION_TYPE): ItemOutputResponse {
    const startDateFinal = this.mergeDateAndTime(control.startDate, control.startHour);
    const endDateFinal = this.mergeDateAndTime(control.endDate, control.endHour);

    const unavailabilityData: Unavailability = {
      id: control.id,
      name: control.title,
      startDate: dateTimeZoneToUTC(startDateFinal.replace('Z', '')),
      endDate: dateTimeZoneToUTC(endDateFinal.replace('Z', '')),
      reason: control.selectedReason,
      toEdit: control.toEdit,
      fromDatabase: control.fromDatabase,
      groupId: control.groupId,
      comment: control.comment,
      dockGradeId: control.dockGradeId,
      type: control.selectedReason.unavailabilityType,
    };
    return {
      item: unavailabilityData,
      itemType: itemType,
      operationType: operationType
    };
  }

  emitItemOutputResponse(itemResponse: ItemOutputResponse): void {
    this.editUnavaibility.emit(itemResponse);
  }

  private extractDate(dateStr: string): Date {
    if (typeof dateStr === 'string') {
      const date = new Date();
      // /!\ be careful this part could be different depending on the date format (english, french..)
      const [dayOfMonth, month, year] = dateStr.split('/').map(Number);
      date.setFullYear(year, month-1, dayOfMonth);
      return date;
    }
    return new Date(dateStr);
  }

  private extractHours(hourStr: string): Date {
    if (this.isValidTimeFormat(hourStr)) {
      const date = new Date();
      const [hours, minutes] = hourStr.split(':').map(Number);
      date.setUTCHours(hours, minutes, 0, 0);
      return date;
    } else {
      return new Date(hourStr);
    }
  }

  private isValidTimeFormat(timeString: string): boolean {
    const regex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
    return regex.test(timeString);
  }

  private hideOverlayAndEmitRefresh(overlay: OverlayPanel): void {
    if (overlay) {
      overlay.hide();
    }
  }

  markAllAsTouched(group: FormGroup) {
    Object.values(group.controls).forEach(control => {
      if (control instanceof FormGroup) {
        this.markAllAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  setFixedDatesIfNeeded(event: any) {
    if(event.value?.onlyAllowClosureAndFixedEndDate) {

      const populatedStartDate = this.unavailabilityForm.get('startDate')?.value;

      this.unavailabilityForm.get('startDate')?.disable();
      this.unavailabilityForm.get('endDate')?.disable();
      this.unavailabilityForm.get('endHour')?.disable();
      this.unavailabilityForm.patchValue(
        { startDate : (populatedStartDate && isToday(populatedStartDate)) ? populatedStartDate : new Date(),
          endDate: this.startDateSelected ?? new Date(),
          endHour: this.fixedEndHour
        });
    } else {
      this.unavailabilityForm.get('startDate')?.enable();
      this.unavailabilityForm.get('endDate')?.enable();
      this.unavailabilityForm.get('endHour')?.enable();
    }
  }

  startDateIsAfterNow(startDate: Date | undefined | null): boolean {
    const now = moment();

    if(!startDate || !(startDate instanceof Date) || isNaN(startDate.getTime())) {
      return false;
    }

    return moment(startDate).isAfter(now);
  }

  protected readonly subscribeOn = subscribeOn;
  protected readonly console = console;
}
