<div class="scheduler-container">
    <!-- Time Column -->
    <div class="time-column">
        <div class="header no-border-bottom"></div>
        <div *ngFor="let time of timeSlots;index as i" class="time-slot time-display"
             [ngClass]="{'first-time-display': i === 0,'last-time-display': i === timeSlots.length - 1}">
            {{ time }}
        </div>
    </div>

    <div class="pistes-container">
        <div *ngFor="let piste of pistes" class="piste-column">
            <div class="header piste-header sticky">
                <span>{{ piste.name }}</span>
                <div class="displayed-grades">
                    <div *ngFor="let grade of displayedGrades" class="grades-container">
                        <span class="grade-header" [ngClass]="getHeaderGradeClass(grade, piste)"> {{ grade }}</span>
                    </div>
                </div>
            </div>
            <!-- Time slots for background grid -->
            <div *ngFor="let time of timeSlots; index as i" class="time-slot"
                 [ngClass]="{'time-slot-last': i === timeSlots.length - 1}"></div>

            <!-- Tasks in each piste -->
            <div
                    *ngFor="let task of piste.tasks"
                    class="task task-display task-font"
                    [ngStyle]="{
          top: getTaskPosition(task.appointmentStartDate),
          height: getTaskHeight(task.appointmentStartDate, task.appointmentEndDate)


        }"
                    (mouseenter)="setTooltipTask(task)" (mouseleave)="setTooltipTask(null)"
            >
                <div class="slotInformationContent" [pTooltip]="tooltipCellContent"
                     tooltipStyleClass="tooltip-info-planning-table"
                     [tooltipPosition]="'right'" [tooltipDisabled]="hoverOverIcon">
                    <span>  {{ task.carrierName }}</span>
                    <div class="d-flex flex-row align-items-center justify-content-between">
                        <div class="d-flex gap-2">
                            <span>{{ task.acNumber }}</span>
                            <button
                                    *ngIf="task.acNumber"
                                    class="me-2 pointer material-icons small-icon-size grid-copy-icon-font copy-icon"
                                    (click)="copyToClipboard(task.acNumber);removeFocus($event)"
                                    (mouseenter)="hoverOverIcon=true"
                                    (mouseleave)="hoverOverIcon=false"
                                    [pTooltip]="tooltipCopyContent"
                                    tooltipEvent="focus"
                                    [life]="1500"
                                    tooltipStyleClass="tooltip-info-planning-table"
                            >
                                content_copy
                            </button>
                        </div>
                        <div class="iconClass">
                            @if (task.loadingStatusTruck === LoadingStatusTruck.LOAD_INIT) {
                                <icon type="local_shipping"
                                      customIconClass="icon-truck material-icons-outlined grid-icon-width"
                                      [pTooltip]="tooltipContent"
                                      tooltipStyleClass="tooltip-info-planning-table"
                                      [tooltipPosition]="'right'"
                                      (mouseenter)="hoverOverIcon=true"
                                      (mouseleave)="hoverOverIcon=false">
                                    <ng-template #tooltipContent>
                                        <ng-container
                                                *ngTemplateOutlet="tooltipTemplate; context: { $implicit: 'truckArrived' }"></ng-container>
                                    </ng-template>
                                </icon>
                            }
                            @if (task.loadingStatusTruck === LoadingStatusTruck.LOAD_START) {
                                <icon type="hourglass_empty"
                                      customIconClass="icon-hourglass material-icons-outlined grid-icon-width"
                                      [pTooltip]="tooltipContent"
                                      tooltipStyleClass="tooltip-info-planning-table"
                                      [tooltipPosition]="'right'"
                                      (mouseenter)="hoverOverIcon=true"
                                      (mouseleave)="hoverOverIcon=false">
                                    <ng-template #tooltipContent>
                                        <ng-container
                                                *ngTemplateOutlet="tooltipTemplate; context: { $implicit: 'loadingOnProgress' }"></ng-container>
                                    </ng-template>
                                </icon>
                            }
                            @if (task.loadingStatusTruck === LoadingStatusTruck.LOAD_END) {
                                <icon type="check_circle_outlined"
                                      customIconClass="icon-check material-icons grid-icon-width"
                                      [pTooltip]="tooltipContent"
                                      tooltipStyleClass="tooltip-info-planning-table"
                                      [tooltipPosition]="'right'" (mouseenter)="hoverOverIcon=true"
                                      (mouseleave)="hoverOverIcon=false">
                                    <ng-template #tooltipContent>
                                        <ng-container
                                                *ngTemplateOutlet="tooltipTemplate; context: { $implicit: 'loadingFinished' }"></ng-container>
                                    </ng-template>
                                </icon>
                            }
                            @if (!task.realLoadingDate && (task.initialStartDate && task.appointmentStartDate) && task.initialStartDate !== task.appointmentStartDate) {
                                <icon type="history_toggle_off" class="history-toggle-icon"
                                      customCssClass="icon-history material-icons"
                                      customIconClass="material-icons large-icon-size grid-icon-width"
                                      [pTooltip]="tooltipReoptimContent"
                                      tooltipStyleClass="tooltip-info-planning-table"
                                      [tooltipPosition]="'right'" (mouseenter)="hoverOverIcon=true"
                                      (mouseleave)="hoverOverIcon=false"></icon>
                            }
                        </div>
                    </div>
                    <span class="grade" [ngClass]="getGradeClass(task.grade)"> {{ task.grade }} </span>
                    <span class="start-time">RDV à {{ task.appointmentStartDate |tooltipFormat:PlanningTooltipContentType.DATE }}</span>

                </div>
            </div>

            <!-- Unavailability -->
            <div
                    *ngFor="let unavailability of piste.unavailabilities"
                    class="unavailability task-display calendar-new-card-unavaibilities"
                    [ngStyle]="{
    top: getTaskPosition(getUnavailabilityDate(unavailability.startDate)) ,
    height: getTaskHeight(getUnavailabilityDate(unavailability.startDate), getUnavailabilityDate(unavailability.endDate))
  }"
                    [pTooltip]="tooltipUnavailabilityContent"
                    tooltipStyleClass="tooltip-info-planning-table"
                    [tooltipPosition]="'right'"
                    (mouseenter)="setTooltipUnavailabilities(unavailability.unavailabilities)"
                    (mouseleave)="setTooltipUnavailabilities(null)"
            >
                <div class="indispo-reason-typo" *ngFor="let unavailabilityElement of unavailability.unavailabilities">
                    <ul>
                        <li>{{ unavailabilityElement.reason.reason }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- tooltip template -->
<ng-template #tooltipCellContent>
    <div class="planning-tooltip-content">
        <ng-container *ngIf="tooltipTask">
            <span>{{ tooltipTask.carrierName }}</span>
            <span>{{ tooltipTask.deliveryAccount }}</span>
            <span><span
                    class="font-weight-normal">BOL_ID:</span> {{ tooltipTask.bolId |tooltipFormat:PlanningTooltipContentType.TRUNCATE }}</span>
            <span><span
                    class="font-weight-normal">Planifié:</span> de {{ tooltipTask.appointmentStartDate |tooltipFormat:PlanningTooltipContentType.DATE }}
                à {{ tooltipTask.appointmentEndDate|tooltipFormat:PlanningTooltipContentType.DATE }}</span>
            <span *ngIf="tooltipTask.realLoadingDate"><span
                    class="font-weight-normal">Chargé:</span> {{ tooltipTask.realLoadingDate|tooltipFormat:PlanningTooltipContentType.DATE }}</span>
        </ng-container>
    </div>
</ng-template>
<!-- truck arrived tooltip template -->
<ng-template #tooltipTemplate let-status>
    <div class="planning-tooltip-content">
        <ng-container *ngIf="tooltipTask">
            <span>{{ getTooltipMessage(status) }}</span>
            <span>
        <span class="font-weight-normal">Début: </span>
                {{ tooltipTask.appointmentStartDate | tooltipFormat: PlanningTooltipContentType.DATE }}
      </span>
            <span>
        <span class="font-weight-normal">Fin: </span>
                {{ tooltipTask.appointmentEndDate | tooltipFormat: PlanningTooltipContentType.DATE }}
      </span>
        </ng-container>
    </div>
</ng-template>

<!-- unavailability tooltip template -->
<ng-template #tooltipUnavailabilityContent>
    <div class="planning-tooltip-content">
        <ng-container *ngIf="tooltipUnavailabilities">
            <div *ngFor="let tooltipUnavailability of tooltipUnavailabilities" class="planning-tooltip-element">
                <span class="planning-tooltip-content-reason">{{ tooltipUnavailability.reason.reason }}</span>
                <span>
                    <span class="font-weight-normal">Début :</span>
                    {{ tooltipUnavailability.startDate | date: 'dd/MM/yyyy - HH\u0027h\u0027mm' : 'UTC' }}
                </span>
                <span>
                    <span class="font-weight-normal">Fin :</span>
                    {{ tooltipUnavailability.endDate | date: 'dd/MM/yyyy - HH\u0027h\u0027mm' : 'UTC' }}
                </span>
            </div>
        </ng-container>
    </div>
</ng-template>


<!-- reoptim tooltip template -->
<ng-template #tooltipReoptimContent>
    <div class="planning-tooltip-content">
        <ng-container *ngIf="tooltipTask">
            <span>Réoptimisation du créneau</span>
            <span><span
                    class="font-weight-normal">Créneau initial:</span> de {{ tooltipTask.initialStartDate |tooltipFormat:PlanningTooltipContentType.DATE }}
                à {{ tooltipTask.initialEndDate|tooltipFormat:PlanningTooltipContentType.DATE }}</span>
            <span><span
                    class="font-weight-normal">Créneau réoptimisé:</span> de {{ tooltipTask.appointmentStartDate |tooltipFormat:PlanningTooltipContentType.DATE }}
                à {{ tooltipTask.appointmentEndDate|tooltipFormat:PlanningTooltipContentType.DATE }}</span>
        </ng-container>
    </div>
</ng-template>

<!-- copy tooltip template -->
<ng-template #tooltipCopyContent>
    <div class="planning-tooltip-content">
        <ng-container>
            <span>Numéro copié</span>
        </ng-container>
    </div>
</ng-template>
