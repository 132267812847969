import {Component, Input} from '@angular/core';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.css'],
})
export class IconComponent {
    @Input() type: string = '';
    @Input() description: string = '';
    @Input() title: string = '';
    @Input() legend: string = '';
    @Input() color: string = '';
    @Input() style: string = '';
    @Input() customCssClass: string = '';
    @Input() customIconClass: string = '';
    @Input() customTextClass: string = '';
    @Input() customDescriptionClass: string = '';


    applyStyle(style: string): string {
        switch (style) {
            case 'outlined':
                return 'material-icons-outlined';
            case 'rounded':
                return 'material-icons-round';
            default:
                return 'material-icons';
        }
    }
}
